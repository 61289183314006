// Filename - main.js

import React, { useEffect, useState } from "react";
import { auth, db } from "./firebase";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { ArrowLeft, CheckCircleFill } from "react-bootstrap-icons";
import { collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";

const Detailspage = () => {
  const [user, loading, error] = useAuthState(auth);
  const [roomno, setRoomNo] = useState(""); // state for the input value
  const [message, setMessage] = useState(""); // state for the feedback message
  let tmpHotel =''
  if(localStorage.getItem("hotel") ){
    tmpHotel = localStorage.getItem("hotel")
  }
  const [hotel, setSelectedOption] = useState(tmpHotel) 
  localStorage.setItem('prvPnr', '');
  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    //       fetchUserName();
  }, [user, loading]);

  const navigate = useNavigate();
  const [result, setResult] = useState("No result");

  const location = useLocation();
  const ticket = location.state.dTicket;

  // function to handle the input change
  const handleChange = (e) => {
    // get the input value
    let value = e.target.value;
   
      setRoomNo(value);
      
   
  };

  // function to handle the button click
  const handleClick = async() => {
    // check if the number is valid
    if (roomno) {
      // create a new document in the firebase collection
      
      // Query for documents with the same pnr 
   
      const collectionRef = collection(db, "finalriders");
      const q = query(collectionRef ,  where("pnr", "==", ticket.pnr));
        const querySnapshot = await getDocs(q);
        // check if the query snapshot is not empty
      if (!querySnapshot.empty) {
        // get the first document from the query snapshot
        const firstDoc = querySnapshot.docs[0];
        // get the docid of the first document
        const docid = firstDoc.id;
        // create a reference to the document with the docid
        const docRef = doc(db, "finalriders", docid);
        // update the document with the roomno
        await updateDoc(docRef, { room: roomno });
        // show a success message
        const users  = JSON.parse(localStorage.getItem("users"));
        const index = users.findIndex(el => el.id === docid);
        // check if the index is valid
        if (index !== -1) {
          // update the user in the users array
          users[index].data.room = roomno;
          // save the updated users array to the localStorage
          localStorage.setItem("users", JSON.stringify(users));
        }
   
        setMessage("Room number updated");
      }
      
    } else {
      // show an error message
      setMessage("Please enter a valid number");
    }
  };


  return (
    <div>
      <div className="App-header">
        <div className="back-btn">
          <Link to={"/rmqrreader"}>
            <ArrowLeft color="#e51743" size={40} />
          </Link>
        </div>
        <img className="App-logo" alt="bobmc" src="Logo.png" />
      </div>
      {user ? (
        <center className="pt-5">
          <div className="col">
            <div className="pb-5"><CheckCircleFill className="text-success" size={70}/><h3 > Rider details</h3></div>
            <div className="trow" >
              
              
              
            </div>
            {Object.entries(ticket).map(([key, value]) => (
              <div className="trow" key={key}>
                <div className="cell uppercase">
                  <strong>{key}</strong>
                </div>
                {(key === 'room')?              
                  (
                  (value === '' || value=== undefined)?
                      <div className="cell">
                      <input type="text" value={roomno} onChange={handleChange} />
                      <button className="ml-3 btn btn-danger" style={{marginLeft:'1em'}} onClick={handleClick}>Save</button>
                      <br/> <p className="text-white">{message}</p>
                    </div>
                  :                 
                    <div className="cell">{value}</div>
                  )
                :
                   <div className="cell">{value}</div>
                }  
              </div>
            ))}
              
         
          </div>
        </center>
      ) : (
        <center>
          <h3>404 Page Not Found </h3>
        </center>
      )}
    </div>
  );
};

export default Detailspage;
