import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Login";

import Mainpage from "./qrreader";
import Dashboard from "./dashboard";
import Detailspage from "./qrdetails";
import ControlPage from "./controlroom";
import VenueDetailsPage from "./venuedetails";
function App() {
  return (
    <div className="app">
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
           <Route exact path="/dashboard" element={<Dashboard/>} />
           <Route exact path="/controlroom" element={<ControlPage/>} />
           <Route exact path="/rmqrreader" element={<Mainpage/>} />
           <Route exact path="/rmqrdetails" element={<Detailspage/>} />
           <Route exact path="/venuedetails" element={<VenueDetailsPage/>} />
      </Routes>
      </Router>
    </div>
  );
}
export default App;