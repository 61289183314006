// Filename - main.js

import React, { useEffect, useState } from "react";
import { auth, db } from "./firebase";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { ArrowLeft, CheckCircleFill } from "react-bootstrap-icons";
import { Timestamp, addDoc, collection, getDocs, orderBy,  query, where } from "firebase/firestore";


const VenueDetailsPage = () => {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");

  const location = useLocation();
  const venue = location.state.venue;
  const [venueriders, setVenueRiders] = useState([]);
  const [hotelCheckins, setHotelCheckins] = useState([]);
  const [checkinCount, setCheckinCount] =useState(0)
  const [checkinTotal, setCheckinTotal] =useState()

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    setName(user?.email);
    // let riders =JSON.parse(localStorage.getItem("allriders")) || [];
    // if(!riders.length){
    //   riders = JSON.parse(localStorage.getItem("users"))
    //   riders = riders.map(rider => rider.data);
    // }

    const queryho = query(collection(db, "finalriders"), where("venue", "==", venue) );
    
    // Get the documents for each query
    getDocs(queryho).then(querySnapshot => {
    
    const riders = querySnapshot.docs.map((doc) =>  doc.data());

    const existsInriders =  riders.filter(obj => obj.venue === venue);
    setCheckinTotal(existsInriders.length)
    let updatedVenueriders =[]
    const query1 = query(collection(db, 'checkins'), where("venue", "==", venue));
    getDocs(query1)
    .then(querySnapshot => {
      if( querySnapshot.size){
        updatedVenueriders = existsInriders.map(rider => {
        let doc = querySnapshot.docs.find(doc => doc.data().pnr === rider.pnr);
        if (doc) {
          // If a matching doc is found, add a checkin field to the rider object
          return { ...rider, checkin: true };
        } else {
          // If no matching doc is found, return the rider object unchanged
          return { ...rider, checkin: false };
        }
      });  
    
    setCheckinCount(updatedVenueriders.filter(rider => rider.checkin).length)
    setVenueRiders(updatedVenueriders.sort((a, b) => a.club.localeCompare(b.club)));
    }
    })
    if(!updatedVenueriders.length)
      setVenueRiders(existsInriders.sort((a, b) => a.club.localeCompare(b.club)))

    })
    
    
   
   

    
    //       fetchUserName();
  }, [user, loading]);

  const navigate = useNavigate();
  const [result, setResult] = useState("No result");


  const ManualCheckin = async (pnr) =>{

    const checkinData = {
      datetime: Timestamp.fromDate(new Date()),
      pnr: pnr,
      venue: venue,
      user: name,
    }
    try {
      const checkinsCollection = collection(db, "checkins");   
      const docRef = await addDoc(checkinsCollection, checkinData)     
      console.log(`Document written with ID: ${docRef.id}`);           
  
     const cvenueriders = venueriders.map(rider => {
        if (rider.pnr === pnr) {
          return { ...rider, checkin: true };
        } else {
          return rider;
        }
       
      });
      setVenueRiders(cvenueriders)

     
     } catch (e) {
       console.error("Error adding document: ", e);
     }
   

  };

  
    
 
  return (
    <div>
      <div className="App-header">
        <div className="back-btn">
         
            <ArrowLeft color="#e51743" size={40}  onClick={() => navigate(-1)}/>
         
        </div>
        <img className="App-logo" alt="bobmc" src="Logo.png" />
      </div>
      {user ? (
        <center className="pt-5">
          <div className="col">
            <div className="pb-5"><h3 >{venue} Rider details</h3></div>
            <p className="h4"> Check-in  {checkinCount} / {checkinTotal}</p>
            <table border="1" className="mx-5">
              <thead>
                <tr >
                  <th className="p-4">PNR</th>
                  <th className="p-4">Club</th>
                  <th className="p-4">Room</th>
                  <th className="p-4">Check-ins</th>
                </tr>
              </thead>
              <tbody>
                {venueriders.map((item, index) => {
                 
                 
                  return (
                    <tr key={index}>
                      <td className="cell m-4 p-4 " >{item.pnr}</td>
                      <td className="cell m-4 p-4 " >{item.club}</td>
                      <td className="cell m-4 p-4 " >{item.room}</td>
                     {(item.checkin)?
                      <td className="cell m-4 p-4 " ><CheckCircleFill color="green" size={30}/></td>
                     :<td className="cell m-4 p-4 "><CheckCircleFill onClick={() => ManualCheckin(item.pnr)} color="gray" size={30}/>
                     </td>
                                        }
                    </tr>
                  );
                })}
              </tbody>
            </table>
           
          </div>
        </center>
      ) : (
        <center>
          <h3>404 Page Not Found </h3>
        </center>
      )}
    </div>
  );
};

export default VenueDetailsPage;
