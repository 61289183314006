// Filename - main.js

import React, { useEffect, useState } from "react";
import { db, auth } from "./firebase";
import { Link, useLocation, useNavigate } from "react-router-dom";
import QrReader from "react-qr-reader";
import { useAuthState } from "react-firebase-hooks/auth";
import {  addDoc,  collection,  getDocs,  query,  where, Timestamp, doc, setDoc} from "firebase/firestore";
import { ArrowLeft } from "react-bootstrap-icons";


const Mainpage = () => {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const location = useLocation();
  let tmpLocation;
  if (location.state) tmpLocation = location.state.hotel;
  else {
    if (localStorage.getItem("hotel")) {
      tmpLocation = localStorage.getItem("hotel");
    } else navigate("/");
  }
  const [hotel, setHotel] = useState(tmpLocation);
  const [message, setMessage] = useState([]);
  const [ticketDetails, setTicketDetails] = useState([]);

 

  const fetchLocalUserDetails = async (pnr, venue) => {
 //   const users = JSON.parse(localStorage.getItem("users"));
    const query1 = query(collection(db, "finalriders"), where("venue", "==", hotel) );
    
    // Get the documents for each query
    const querySnapshot = await getDocs(query1)
    
    const users = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));

    let found = false;
    users.forEach((tuser) => {
      if (tuser.data.pnr === pnr) {
        console.log("Found user:", tuser);
        found = true;
        // update checkedin time
        updateStatusChecked(tuser, pnr).then(checkedStatus => {

          
          const dTicket = displayResults(tuser.data, pnr);
          if (checkedStatus) {

            // if rider already checked in
            setMessage("Rider already checked in by "+checkedStatus);
            alert("Rider already checked in by "+checkedStatus);           
            setTicketDetails(dTicket)
           
          } else { 
             // Display the user details            
             navigate("/rmqrdetails", { state: { dTicket } });
          }
          
        });
       
      }
    });
    if (!found) {
      console.log("No user found with this PNR.");
      setMessage(
        <>
          Please check the pnr and confirm the venue!!! Wrong Venue
          <br />
          Checkin at {venue}
        </>
      );
      alert("Please check the pnr and confirm the venue!!! Wrong Venue");    
      setTicketDetails("")
    }
  };

  const updateStatusChecked = async (cuser, pnr) => {
    // remote Db update
  
    const checkinsCollection = collection(db, "checkins");
     // Query for documents with the same pnr 

    const q = query(checkinsCollection, where("pnr", "==", pnr))
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      // No documents with the same pnr, add the new document
    const checkinData = {
      datetime: Timestamp.fromDate(new Date()),
      pnr: pnr,
      venue: cuser.data.venue,
      user: name,
    }
    try {
      
     const docRef = await addDoc(checkinsCollection, checkinData)     
     console.log(`Document written with ID: ${docRef.id}`);           
     return 0
    
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }
  else {
    //let datetime = Moment.globalTimezone(.toDate(), 'Asia/Kolkata');
    let datetime = querySnapshot.docs[0].data().datetime.toDate();
    let readableDate = datetime.toLocaleDateString();
    let readableTime = datetime.toLocaleTimeString();
    const userdet = querySnapshot.docs[0].data().user +" at "+ readableDate +" "+readableTime
    console.log('Already checkedin.');
    return userdet
  }
  };


  const displayResults = (data, pnr) => {
          
    const ticket = {};
    ticket["room"] =data.room;
    ticket["venue"] = data.venue;       
      ticket["pnr"] = data.pnr;
      ticket["name"] = data.name;
      ticket["email"] = data.email;
      ticket["club"] = data.club;
      ticket["bloodgroup"] = data.bloodGroup;
      ticket["food"] = data.food;
      ticket["tshirt"] = data.tshirt;
    
    //   setResult(ticket);
    return ticket
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");

    setName(user?.email);
  }, [user, loading]);

  const navigate = useNavigate();

  const previewStyle = {
    height: 240,
    width: 320,
  };
  const constraints = {
    facingMode: "environment",
  };
  const [result, setResult] = useState("No result");

  const handleScan = (data) => {
   
    if (data) {

      const match = data.match(/PNR: ([\w\s]+)/);
      const matchvenue = data.match(/Venue: ([\w\s]+)/); 
      if (match) {       
        const pnr = match[1].trim()
      
       fetchLocalUserDetails(pnr, matchvenue[1].trim());
        
        //        console.log('PNR:', pnr); // Outputs: PNR: RM24 75 S4 1017
      } else {
        console.log("No PNR found in the string.");
      }
    }
  };

  const handleError = (error) => {
    console.error(error);
  };

  return (
    <div>
      <div className="App-header">
        <div className="back-btn">
          <Link to={"/"}>
            <ArrowLeft color="#e51743" size={40} />
          </Link>
        </div>
        <img className="App-logo" alt="bobmc" src="Logo.png" />
      </div>
      <center>
      {user ? (
        <div>
          
            <div>
              <h3>Checking into {hotel}</h3>
              <div className="text-danger">{message}</div>
              <br />
          
              <QrReader
                delay={1200}
                onError={handleError}
                onScan={handleScan}
                style={previewStyle}
                facingMode={"environment"}
              />
            </div>
         
          <br clear="all" />
          <div style={{ marginTop: '5em' , marginBottom:'2em' }}>
       
          {Object.entries(ticketDetails).map(([key, value]) => (
            <>   
            <div className="trow" key={key}>
              <div className="cell uppercase">
                <strong>{key}</strong>
              </div>
              <div className="cell">{value}</div>
            </div>
            </>
          ))}
          </div>
        </div>

      ) : (
        
          <h3>404 Page Not Found </h3>
       
      )}
      </center>
    </div>
  );
};

export default Mainpage;
