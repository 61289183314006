import React, { useEffect,  useState } from "react";
import { db, auth } from "./firebase";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";

import { DatabaseDown, Eye, QrCodeScan } from "react-bootstrap-icons";
import { Grid } from "@material-ui/core";
import DateDisplay from "./datedisplay";
import Moment from "react-moment";

const Dashboard = () => {
  const [user, loading, error] = useAuthState(auth);
  const [hotellist, setHotellist] = useState([]);
  const [users, setUsers] = useState([]);
  const [nousers, setNoUsers] = useState(0);

  
  let tmpHotel = "";
  if (localStorage.getItem("hotel")) {
    tmpHotel = localStorage.getItem("hotel");
  }
  const [hotel, setSelectedOption] = useState(tmpHotel);
  const [msg, setMessage] = useState("");

  const handleChange = (event) => {
    setSelectedOption(event.target.value);

    fetchUsersFromHotel(event.target.value);
    fetchCheckinsByHotel(event.target.value)
  };

  const fetchUsersFromHotel = async (hotel) => {
    const query1 = query(collection(db, "finalriders"), where("venue", "==", hotel) );
    
    // Get the documents for each query
    const querySnapshot = await getDocs(query1)
    
    const data = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));
    localStorage.removeItem("users");
    localStorage.removeItem("hotel");
    localStorage.setItem("hotel", hotel);
    localStorage.setItem("users", JSON.stringify(data));
  //  setMessage(hotel + " guest list has been updated to localstorage");
    
  };

  const fetchHotels = async (pnr) => {
    const collectionRef = collection(db, "hotels");
    const q = query(collectionRef, orderBy("name"));
    const querySnapshot = await getDocs(q);
    const data = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));

    const options = data.map((item) => ({
      value: item.data.name,
      label: item.data.name,
    }));

    setHotellist(options);
  };


  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    fetchHotels();
  }, [user, loading]);

  useEffect( () => {
    fetchCheckinsByHotel(hotel)
   
  }, []);

  const fetchCheckinsByHotel = async(selHotel) =>{
    if(selHotel !== ""){
      const collectionRef = collection(db, "checkins");
      const q = query(collectionRef,  where("venue", "==", selHotel));
      await getDocs(q).then(querySnapshot => {
        
        // create a Set to store the unique pnr values
        const uniquePnr = new Set();
        // map over the querySnapshot.docs array and extract the data objects
        const check = querySnapshot.docs.map((doc) => {
          // get the data object from the doc
          const data = doc.data();
          // get the pnr value from the data
          const pnr = data.pnr;
          // add the pnr value to the Set
          uniquePnr.add(pnr);
          // return the data object
          return { data };
        });
        // filter the check array to keep only the unique data objects
        const uniqueCheck = check.filter((item) => {
          // get the pnr value from the data object
          const pnr = item.data.pnr;
          // check if the pnr value is in the Set
          if (uniquePnr.has(pnr)) {
            // delete the pnr value from the Set
            uniquePnr.delete(pnr);
            // return true to keep the item
            return true;
          } else {
            // return false to discard the item
            return false;
          }
        });
// update the users state with the uniqueCheck array
setUsers(uniqueCheck);
        const tpCheckins = JSON.parse(localStorage.getItem("users"));
        let countPNR = 0;
       
        for(let chkuser of tpCheckins) {
            if(chkuser.data.pnr !== "") {
                countPNR++;
            }
          
        }
        setNoUsers(countPNR)
        });
      
     }

  }

  const navigate = useNavigate();

  return (
    <div className="container">
      <div className="App-header">
      <img className="App-logo" alt="bobmc" src="Logo.png" />
        
      </div>

      <div style={{ marginTop: 50 }}>
        {user ? (
          <div className="">
            <center>
            <div className="">
            <span className="form-label">Hotels</span>
            <div className="col-md-3 d-flex align-items-center">
 
  <select
    value={hotel}
    onChange={handleChange}
    className="form-control"
  >
    <option value="">Select...</option>
    {hotellist.map((option, index) => (
      <option key={index} value={option.value}>
        {option.value}
      </option>
    ))}
  </select>
  {/* <DatabaseDown
    color="#e51743"
    size={30}
    
    onClick={() => {
      if (hotel !== "") fetchUsersFromHotel(hotel);
      else setMessage("Select a hotel ");
    }}
    style={{ cursor: "pointer", marginLeft: "10px" }}
  /> */}
  <Eye
    color="#e51743"
    size={30}
    
    onClick={() => { const venue = hotel
      navigate("/venuedetails", { state: { venue } });
    }}
    style={{ cursor: "pointer", marginLeft: "10px" }}
  />
</div>

  <br clear="all" />

</div>
              <div className="text-danger">{msg}</div>
              <div className="mt-5 col">
                <QrCodeScan
                  color="#e51743"
                  size={100}
                  onClick={() => {
                    if (hotel !== "")
                      navigate("/rmqrreader", { state: { hotel } });
                    else setMessage("Select a hotel ");
                  }}
                 
                  style={{ cursor: "pointer" }}
                />
                
              </div>
           
            <div className="mt-5" >CHECK-INS <br/>
            <span className="display-4">{users.length}/{nousers}</span></div>       
            <table className="mt-3" border="1">
              <thead>
                <tr>
                  <th>PNR</th>
                 
                  <th>CHECKED IN</th>
                  {/* Add more columns as needed */}
                </tr>
              </thead>
              <tbody>
                {users.map((chuser, index) => (
                  <tr key={index}>
                    <td className="ps-5 pe-5"><p>{chuser.data.pnr}</p></td>
                  
                    <td className="ps-5 pe-5"> <Moment format="YYYY/MM/DD HH:mm:ss">{chuser.data.datetime.toDate()}</Moment></td>
                    {/* Add more cells as needed */}
                  </tr>
                ))}
              </tbody>
            </table>
            </center>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
