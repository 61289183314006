// Filename - control.js

import React, { useEffect, useState } from "react";
import { db, auth } from "./firebase";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import {  collection,  doc,  getDocs,  orderBy,  query,  updateDoc,  where,} from "firebase/firestore";

import { Bar, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis, BarChart, PieChart, Pie, Cell, Label } from "recharts";

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
let totalcheckins =[]
const percentCheckin ={}
const ControlPage = () => {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
 
//  const [totalcheckins, setTotalCheckins] = useState([]);
  const [checkins, setCheckins] = useState([]);
  const [message, setMessage] = useState([]);
  const [chartdata, setChartData] = useState([])
  const [clubdata, setClubData] = useState([])
  const [clubcount, setClubCount] = useState([])
  const [clubtotals, setClubtotals] = useState([])

  const fetchAllCheckins = async () => {
   
    const hotelCheckins = [];
    const q = query(collection(db, "hotels"), orderBy("name"));
    const querySnapshot = await getDocs(q);
    localStorage.setItem("totalcheckins","")

    const data = await Promise.all(querySnapshot.docs.map(async (doc) => {
        const query1 = query(collection(db, 'finalriders'), where("venue", "==", doc.data().name));
        const snapshot = await getDocs(query1);
        hotelCheckins.push({[doc.data().name]: snapshot.size});
    }));
   
    localStorage.setItem("totalcheckins", JSON.stringify(hotelCheckins));
    console.log(hotelCheckins); // to check the result
  //  setTotalCheckins(hotelCheckins)
  totalcheckins = [...hotelCheckins]

    
     
  };

  

  const fetchCheckinsByHotel = async() =>{

    const hotelCheckins = [];
    const q = query(collection(db, "hotels"), orderBy("name"));
    const querySnapshot = await getDocs(q);
    let pnrArray = [];
    let clubCounts = {};
    const data = await Promise.all(querySnapshot.docs.map(async (doc) => {
        const query1 = query(collection(db, 'checkins'), where("venue", "==", doc.data().name));
        const snapshot = await getDocs(query1);
       // create a Set to store the unique pnr values
        const pnrSet = new Set();
        snapshot.docs.forEach(doc => {
          const data = doc.data();
          if (data.pnr) {
            
            
          // add the pnr value to the Set
          pnrSet.add(data.pnr);
 
          }
        });
        pnrArray = [...pnrArray, ...pnrSet];
        hotelCheckins.push(pnrSet.size);
    }));
    setCheckins(hotelCheckins)
    
    let chartData = []
  
    totalcheckins.map((item, index) => {
      const key = Object.keys(item)[0];
      const value = item[key];
      chartData.push({ label: key, checkins: hotelCheckins[index]  , total: value });

  })
  setChartData(chartData)
  const riderCheckins = JSON.parse(localStorage.getItem('allriders')) || [];
  // Using find - returns the first match
  pnrArray.forEach(doc => {
    const result = riderCheckins.find(obj => obj.pnr === doc);
    if (result && result.club) {
      if (!clubCounts[result.club]) {
        clubCounts[result.club] = 0;
      }
  
        clubCounts[result.club]++;
   //     percentCheckin[result.club] =  

    }
  })
  const clubcheckins = []
  let uniqueNames = [...new Set(riderCheckins.map(item => item.club))];
  const clubs = uniqueNames.map((club)=>{
    
    clubcheckins.push({ name: club, value:  clubCounts[club] });
    

  })
  setClubCount(clubcheckins)

  };

  const GetallRiders = async() =>{
   const clubtotalCount =[]
    const riderCheckins = JSON.parse(localStorage.getItem('allriders')) || [];
    if(!riderCheckins.length){
      const q = query(collection(db, "finalriders"), orderBy("venue"));
      const querySnapshot = await getDocs(q);
      const data = await Promise.all(querySnapshot.docs.map(async (doc) => {          
        riderCheckins.push( doc.data());
      }));

     
      localStorage.setItem('allriders', JSON.stringify(riderCheckins));
    }
    let uniqueNames = [...new Set(riderCheckins.map(item => item.club))];
    const clubtotal={}
    
    riderCheckins.map((rider)=>{
      if (!clubtotal[rider.club]) {
        clubtotal[rider.club] = 0;
      }
      clubtotal[rider.club]++
    })
    setClubtotals(clubtotal)
    const clubs = uniqueNames.map((club)=>{
    
      clubtotalCount.push({ name: club, value:  clubtotal[club] });
   

    })
   
    setClubData(clubtotalCount)
    console.log(uniqueNames); // Outputs: ['Alice', 'Bob', 'Charlie']
    
  }

  const GetcheckinsByVenue = async(venue) =>{
    navigate("/venuedetails", { state: { venue } });
    
  };


  const GetRiderDetails = async () =>{
    GetallRiders()
    if(!JSON.parse(localStorage.getItem("totalcheckins")))
      fetchAllCheckins()
    else
    {
    //  setTotalCheckins(JSON.parse(localStorage.getItem("totalcheckins")))
    totalcheckins = JSON.parse(localStorage.getItem("totalcheckins"))
    }
    GetHotelDetails()
  }

  const GetHotelDetails = async () =>{

    if (totalcheckins.length > 0) {
      fetchCheckinsByHotel()
    }

  }

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    GetRiderDetails()
  
    setName(user?.email);
  }, [user, loading ]);
  
  useEffect(() => {

   
    
  }, [clubcount]); // Separate useEffect that runs when totalcheckins changes

  const navigate = useNavigate();
 
  
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
  
  const percentage = 66;

  return (
    <div>
      <div className="App-header">
      
        <img className="App-logo" alt="bobmc" src="Logo.png" />
        {/* <button className="fetchall-btn btn btn-danger" onClick={GetallRiders}>Get all Riders</button> */}
      </div>
      <center>
      {user ? (
       <div> 
       
        <div className="row">
        <h2>Total Check-ins</h2>
      <div className="section col-md-12">
      
        <div className="section-content">
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={chartdata} margin={{ top: 15, right: 0, bottom: 15, left: 0 }}>
              <XAxis dataKey="label" />
              <YAxis />
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <Tooltip />
              <Legend/>
              <Bar dataKey="checkins" fill="#FB8833" />
              <Bar dataKey="total" fill="#17A8F5" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="row">
       <h2>Clubs</h2>

        <div className="section col-md-6">
      
      <div className="section-content">
      
        <PieChart width={800} height={400}>
       <Pie 
       data={clubdata} 
       dataKey="value"
       innerRadius={100}
       outerRadius={150} 
       fill="#8884d8"
       paddingAngle={2}
       >
       <Label 
       value="Total" position="centerBottom"  className='label-top' fontSize='27px'
       />
       
         {
           clubdata.map((entry, index) => <Cell 
           fill={COLORS[index % COLORS.length]}
          
         />)
         }
       </Pie>
       <Tooltip/>
      </PieChart>

      </div>
    </div>
    <div className="section col-md-6">
      
      <div className="section-content">
    <PieChart width={800} height={400}>
       <Pie 
       data={clubcount} 
       dataKey="value"
       innerRadius={100}
       outerRadius={150} 
       fill="#8884d8"
       paddingAngle={2}
       >
       <Label 
       value="check-ins" position="centerBottom"  className='label-top' fontSize='27px'
       />
       
         {
           clubcount.map((entry, index) => <Cell key={`cell-${index}`}
           fill={COLORS[index % COLORS.length]}
          
         />)
         }
       </Pie>
       <Tooltip/>
      </PieChart>
      </div>
      </div>
      </div>
   
    </div>
       <table border="1" className="mx-5">
       <thead>
         <tr >
           <th className="p-4">Hotel Name</th>
           <th className="p-4">Check-ins</th>
         </tr>
       </thead>
       <tbody>
         {totalcheckins.map((item, index) => {
           const key = Object.keys(item)[0];
           const value = item[key];
           return (
             <tr key={index}>
               <td className="cell m-4 p-4 pointer" onClick={() => GetcheckinsByVenue(key)}>{key}</td>
               <td className="cell m-4 p-4">{checkins[index]} / {value}</td>
             </tr>
           );
         })}
       </tbody>
     </table>
     </div>
      ) : (
        
          <h3>404 Page Not Found </h3>
       
      )}
      </center>
    </div>
  );
};

export default ControlPage;
